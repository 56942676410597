var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user"},[_c('div',{staticClass:"line_one"},[_c('div',{staticClass:"goods_img"},[_c('div',{staticClass:"item_img"},[_c('img',{attrs:{"src":_vm.dataList.messageImage}})])]),_c('div',{staticClass:"goods_text"},[_c('h1',[_vm._v(_vm._s(_vm.dataList.messageName))]),_c('textFlow',{staticClass:"text_area",attrs:{"info":_vm.dataList.messageDescribe,"hiddenBtn":true,"lineClamp":4}})],1)]),_c('div',{staticClass:"line_two"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"data_list",staticStyle:{"width":"97%"},attrs:{"data":_vm.getAttentionList,"header-cell-style":_vm.getRowClass}},[_c('el-table-column',{attrs:{"label":"用户ID","prop":"id","align":"center","width":"80"}}),_c('el-table-column',{attrs:{"label":"头像","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"headImg"},[_c('img',{attrs:{"src":row.headPortrait}})])]}}])}),_c('el-table-column',{attrs:{"label":"真实姓名","prop":"userName","align":"center","width":"80"}}),_c('el-table-column',{attrs:{"label":"手机号","prop":"mobile","align":"center","width":"120"}}),_c('el-table-column',{attrs:{"label":"机构","prop":"institution","align":"center","width":"100"}}),_c('el-table-column',{attrs:{"label":"职位","prop":"position","align":"center","width":"80"}}),_c('el-table-column',{attrs:{"label":"标签","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.labelList.length != 0)?_c('div',{staticClass:"tip_two"},_vm._l((row.labelList),function(item,index){return _c('div',{key:index},[_vm._v(_vm._s(item))])}),0):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"用户等级","prop":"whetherMember","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.whetherMember == 0 ? '普通会员' : '超级会员')+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"信息描述","align":"center","width":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('textFlow',{attrs:{"info":row.personalIntro,"lineClamp":2,"hiddenBtn":true}})]}}])})],1)],1),_c('div',{staticClass:"new_page"},[_c('el-pagination',{attrs:{"current-page":_vm.queryInfo.current,"page-sizes":[10, 30, 50],"page-size":_vm.queryInfo.size,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }