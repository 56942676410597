<template>
 <div class="user">
    <div class="line_one">
      <div class="goods_img">
        <div class="item_img">
          <img :src="dataList.messageImage">
        </div>
      </div>
      <div class="goods_text">
        <h1>{{dataList.messageName}}</h1>
        <textFlow :info="dataList.messageDescribe" :hiddenBtn='true' :lineClamp='4' class="text_area"></textFlow>
      </div>
    </div>
    <div class="line_two">
      <el-table :data="getAttentionList" style="width: 97%" class="data_list" v-loading="loading" :header-cell-style="getRowClass">
          <el-table-column label="用户ID" prop="id" align="center" width="80" />
          <el-table-column label="头像" width="100" align="center">
              <template v-slot="{ row }">
              <div class="headImg">
                <img
                :src="row.headPortrait"
                />
              </div>
              </template>
          </el-table-column>
          <el-table-column label="真实姓名" prop="userName" align="center" width="80" />
          <el-table-column label="手机号" prop="mobile" align="center" width="120" />
          <el-table-column label="机构" prop="institution" align="center" width="100" />
         
          <el-table-column label="职位" prop="position" align="center" width="80" />
          <el-table-column label="标签" align="center">
            <template v-slot="{ row }">
            <div class="tip_two" v-if="row.labelList.length != 0">
              <div v-for="(item,index) in row.labelList" :key="index">{{item}}</div>
            </div>
          </template>
          </el-table-column>
         <el-table-column label="用户等级" prop="whetherMember" align="center"  width="100">
          <template v-slot="{ row }">
            <div>
              {{row.whetherMember == 0 ? '普通会员' : '超级会员'}}
            </div>
          </template>
         </el-table-column>
          <el-table-column label="信息描述" align="center" width="400">
            <template v-slot='{ row }'>
              <textFlow :info="row.personalIntro" :lineClamp='2' :hiddenBtn="true"></textFlow>
            </template>
          </el-table-column>
      </el-table>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.current"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>


<script>
import textFlow from '@/components/textflow.vue'
import { pagination } from '@/mixins/pagination'
import { getReadUser, getDetail } from '@/api/infoPush.js'
const defaultQueryInfo = Object.freeze({
  current: 0,
  size: 10,
})
export default {
  props:{
    topicId:{
      type:String
    }
  },
  components: { textFlow },
  mixins: [pagination],
  data () {
    return {
        getAttentionList:[],
        dataList:[],
        loading:false,
        queryInfo: { ...defaultQueryInfo },
    }
  },
  mounted(){    
    this.queryInfo.readStatus = this.$route.query.readStatus
    this.queryInfo.messageId = this.$route.query.messageId
    if(this.queryInfo.readStatus == 1){
      this.$route.meta.title = '已读信息'
    }else{
      this.$route.meta.title = '未读信息'
    }
    this.search()
    this.getDetail(this.queryInfo.messageId)
  },
  methods: {
    async getDetail(id){
      const { data: res } = await getDetail({id:id})
      // console.log(res.data,"123");
      if(res.data.messageImage) res.data.messageImage = res.data.messageImage.split(',')[0]
      this.dataList = res.data
    },
    async search() {
      this.loading = true
      const { data: res } = await getReadUser(this.queryInfo)
      if (res.resultCode === 200) {
        if(res.data) this.getAttentionList = res.data.list
        if(res.data) this.total = res.data.total
        this.loading = false
      }
    },
  },
}
</script> 

<style scoped lang="less">
.headImg{
  margin: 0px auto;
  width: 45px;
  height: 45px; 
  img{
    width: 100%;
    height: 100%;
  }
}
.user{
  width: 100%;
  overflow: hidden;
  height: auto !important;
  margin: 15px 0px 0px 20px;
}
.line_one{
  width: 100%;
  height: 120px;
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  .goods_img{
    width: 200px;
    .item_img{
      width: 140px;
      height: 90px;
      border-radius: 8px;
      margin: 16px 16px 16px 20px;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .goods_text{
    width: 80%;
    margin-top: 16px;
    .text_area{
      width: 100%;
      color: #333333;
      font-size: 12px;
      line-height: 18px;
      img{
        width: 100px;
        height: 100px;
      }
    }
  }
} 
.line_two{
  background: #ffffff;
  margin-top: 20px;
  overflow: hidden;
  border-radius: 12px;
  .data_list{
    margin: 20px 0px 0px 20px;
  }
}
.new_page {
  width: 100%;
  text-align: center;
  margin: 30px auto;
}
.tip_two{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 8px;
  div{
    padding: 2px 5px;
    margin-top: 5px;
    font-size: 12px;
    line-height: 17px;
    color: #7067F2;
    background: #7067F21A;
    margin-right: 8px;
  }
}
</style>
